import React from 'react';
import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const ChristmasTermsPage = () => (
  <>
    <SEO title="Christmas Giveaway Terms and Conditions" />
    <TermsSection
      title="Christmas Giveaway Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
    <>
      <p>
        We are giving a cash or voucher prize to one customer a week for three weeks.
        <br/><br/>
        The campaign will be running from 02/12/2024 to 23:59 on 22/12/24.
        <br/><br/>
        To be entered in the giveaway:
        <br/><br/>
        You must purchase a Sprive shopping card in the app of at least £5 before each weeks deadline which will be 23:59 every Sunday.
        <br/><br/>
        Each shopping card purchase through Sprive = 1 entry into the Giveaway campaign
      </p>
      <p>
        <ul>
          <li>
            We will choose a winner at random, using a random number generator every Monday during the campaign.
          </li>
          <br/>
          <li>
            The winner will be contacted from <a href="mailto:hello@sprive.com" target="_blank"
                                                 rel="noopener noreferrer">hello@sprive.com</a> only.
          </li>
          <br/>
          <li>
            Voucher prizes will be sent to the email address associated with your Sprive account.
          </li>
          <br/>
          <li>
            For the cash prizes available you can choose to either have the money won credited to your Sprive e-wallet
            account to put towards your mortgage (this can't be withdrawn) or transferred to your bank account
          </li>
          <br/>
        </ul>
      </p>

      <h3>
        We will only contact you from our email address hello@sprive.com
      </h3>

    </>
);

export default ChristmasTermsPage;
